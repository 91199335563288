@import "./variables.scss";
@import "./mixins.scss";

/**
  Custom App
*/
app-view-master {
    width: 100%;
    min-height: calc(100vh - 130px);
    overflow-y: visible;
    display: block;
}

app-ui-blocker {
    width: 100%;
    min-height: calc(100vh - 130px);
    display: block;
    overflow: visible;

    .loading-message {
        display: block;
        text-align: center;
        animation: pulse 5s infinite;
        @include font-size(24);
    }
}

section.app-content {
    padding: 24px;

    .page-header {
        h1 {
            @include font-size(24);
            margin-bottom: 4px;
            color: $brand-color-primary;
        }
        small {
            @include font-size(18);
        }
    }
}

mat-slide-toggle {
    padding: 0 16px;
    .mat-slide-toggle-content {
        @include font-size(14);
    }
}

app-form-master {
    formly-field {
        padding: 6px 0;
        margin: 6px 0;
        height: 85px;
    }

    .mat-mdc-form-field {
        @include font-size(16);
    }
    .mat-radio-label-content {
        padding-right: 8px;
    }
}

.header-username {
    color: $brand-color-primary;
    display: inline-block;
    vertical-align: middle;
    @include font-size(12);
}

.filter-form {
    > form,
    formly-form formly-field formly-group:first-child {
        display: flex;
        flex-wrap: wrap;

        formly-field {
            max-width: 20%;
            padding: 6px 6px;
        }
    }

    .form-actions {
        justify-content: normal !important;

        button[type="submit"] {
            display: none;
        }

        button.discard-button {
            background: none;
            color: $brand-color-primary;
            text-decoration: underline;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .unsaved-changes-message {
        display: none !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
}

.filter-form-performance {
    > form,
    formly-form formly-field formly-group:first-child {
        display: flex;
        flex-wrap: wrap;

        formly-field {
            max-width: 50%;
            padding: 6px 6px;
        }
    }
}

.app-card {
    box-shadow: none !important;
}

.density-chart-page {
    .mat-mdc-paginator {
        visibility: hidden !important;
        height: 1px !important;
    }

    .table-wrapper {
        max-height: 10000px !important;
    }
}

/**
  Custom Denisty Chart table 
*/
.density-chart-page {
    table.mat-mdc-table {
        border-spacing: 0;
    }

    tr.mat-mdc-header-row {
        height: 56px;
    }

    tr.mat-mdc-row,
    tr.mat-mdc-footer-row {
        height: 48px;
    }

    th.mat-mdc-header-cell {
        text-align: left;
    }

    th.mat-mdc-header-cell,
    td.mat-mdc-cell,
    td.mat-mdc-footer-cell {
        padding: 0;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    th.mat-mdc-header-cell:first-child,
    td.mat-mdc-cell:first-child,
    td.mat-mdc-footer-cell:first-child {
        padding-left: 24px;
    }

    th.mat-mdc-header-cell:last-child,
    td.mat-mdc-cell:last-child,
    td.mat-mdc-footer-cell:last-child {
        padding-right: 24px;
    }
}

/**
Custom Task Manager Form 
*/
.task-detail-page {
    .form-master {
        max-width: 500px !important;
    }

    .form-actions {
        flex-direction: row-reverse;

        button[type="submit"] {
            margin-left: 0 !important;
            margin-right: 12px !important;
            background: $brand-color-secondary;
        }

        button.delete-btn {
            margin-left: 0 !important;
            margin-right: 12px !important;
            background: $brand-color-danger;
        }

        button.discard-button {
            background: none;
            color: $brand-color-primary;
            border: 1px solid $brand-color-primary;
        }
        .unsaved-changes-message {
            display: none !important;
        }
    }

    .mat-mdc-slide-toggle.mat-checked {
        .mat-slide-toggle-thumb {
            background: $brand-color-secondary !important;
        }

        .mat-slide-toggle-bar {
            background: $brand-color-secondary-fade !important;
        }
    }

    .formly-flex-group-crate {
        border-bottom: 1px solid $brand-color-primary;

        h3 {
            padding-bottom: 12px;
            border-bottom: 1px solid $brand-color-primary;
        }
    }

    .formly-flex-group-crate formly-group,
    .formly-flex-group > formly-group {
        display: flex;
        flex-wrap: wrap;

        .col-12 {
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
        }

        .col-4 {
            width: 33.33333%;
            flex-grow: 1;
            flex-shrink: 0;
            padding-right: 12px;

            &:last-child {
                padding-right: 0;
            }
        }

        .col-6 {
            width: 50%;
            flex-grow: 1;
            flex-shrink: 0;
            padding-right: 12px;

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

/**
Custom Variety Manager Page / Admin
*/

.variety-create-page {
    .discard-button {
        display: none !important;
    }
}

.variety-manager-page {
    .form-actions {
        display: none !important;
    }
}

.variety-create-page,
.variety-manager-page {
    .formly-flex-group-varieties formly-group {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        padding: 12px 0 0 0;
        margin: 0 !important;
        border-top: 1px solid $brand-color-primary;

        > formly-field {
            padding: 0 12px 0 0;
            margin: 0;

            .mat-form-field-wrapper {
                margin: 0 !important;
                padding: 0 !important;
            }

            &:nth-last-child(2) {
                margin-left: auto;
            }
            &:last-child {
                padding-right: 0;
            }
            &:nth-last-child(2),
            &:last-child {
                button {
                    color: #4b5b6b;
                    height: 66px;
                    border-bottom: none !important;
                }

                .mat-form-field-underline {
                    display: none !important;
                }

                .mat-form-field-wrapper,
                .mat-form-field-infix {
                    width: 70px;
                    border-top: none !important;
                    margin: 0 !important;
                    padding: 0 !important;
                }
            }
        }
    }
}

/**
Custom Performance Report Table
*/
table span.performance-time {
    @include font-size(24);
    display: block;
    text-align: right;

    &.success {
        color: $brand-color-secondary;
    }
    &.failure {
        color: $brand-color-danger;
    }
}

table .mat-column-actions {
    text-align: right;
}

table .max-col-width {
    max-width: 300px;
}

/**
Custom Performance Detail page
*/

app-performance-detail {
    .page-header {
        text-align: center;

        h1 {
            position: relative;
            color: $brand-color-primary;

            button {
                position: absolute;
                left: 0;
            }
        }
    }
    .filter-form {
        > form,
        formly-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        formly-field {
            max-width: 40%;
            padding: 6px 6px;
        }

        .form-actions {
            display: none !important;
        }
    }
    .mat-column-performanceTime {
        button {
            text-align: right !important;
        }
        > span {
            display: block;
            text-align: right;
        }

        .performance-date {
            @include font-size(24);
        }
    }
}

.file-hidden {
    display: none !important;
}

.no-results {
    color: $brand-color-primary;
}
