/**
 * https://material.io/resources/color - design the palette
 * http://mcg.mbitson.com/ - generate the palette map
 **/

@use "@angular/material" as mat;
@import "./light-palette.scss";
@import "./dark-palette.scss";
@import "./variables.scss";

@include mat.core();

body.light-theme {
    @include mat.all-component-themes($custom-light-theme);
}
