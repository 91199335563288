/**
 * Converts pixels into REM
 *
 * Usage: @include font-size($fs: 14px, $sm: subpixel-antialiased, $lh: 1.5);
 */
@use "sass:math";

html {
    font-size: 62.5%;
} // set the base font size to 10px to take away the math

$default-line-height: 1.5 !default;
$default-font-smoothing: subpixel-antialiased !default;

// converts pixel values into rem values
@function px-to-rem($size) {
    @return math.div($size, 10) + rem;
}

// a mixin that applies responsive font sizes, line height and anti-aliasing
@mixin font-size(
    $fs: false,
    $lh: $default-line-height,
    $sm: $default-font-smoothing
) {
    @if $fs {
        font-size: $fs + px;
        font-size: px-to-rem($fs);
    }
    @if $lh {
        line-height: $lh;
    }
    @if $sm {
        -webkit-font-smoothing: $sm;
    }
}

// A helpful mixin for debugging
@mixin debug($value) {
    @debug "value : #{$value}";
}
