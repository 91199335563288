@import "./light-palette.scss";
@import "./dark-palette.scss";
@import "./variables.scss";
@import "./mixins.scss";

html,
* {
    font-family: "Roboto", "Helvetica Neue", sans-serif;
}

body.light-theme .mat-toolbar.mat-primary,
body.dark-theme .mat-toolbar.mat-primary {
    background: $brand-color-primary;

    * {
        color: #fafafa;
    }
}

section.app-content {
    width: 80%;
    margin: 0 auto;
}

body.light-theme {
    app-ui-blocker {
        background: map-get($custom-light-palette, 400);

        .loading-message {
            @include font-size(10);
            text-transform: uppercase;
            padding-left: 22px;
            margin: 24px;
            color: map-get(map-get($custom-light-palette, contrast), 400);
        }
    }
    mat-slide-toggle {
        .mat-slide-toggle-content {
            color: map-get(map-get($custom-light-palette, contrast), 400);
        }
    }

    #app_content > mat-card {
        background: $not-quite-white;
        border: none;
    }

    .mat-toolbar {
        background: $not-quite-white;
        border-bottom: 1px solid $brand-color-primary;
    }
}

body.dark-theme {
    app-ui-blocker {
        background: map-get($custom-dark-palette, 400);

        .loading-message {
            padding-left: 22px;
            color: map-get(map-get($custom-dark-palette, contrast), 400);
        }
    }
    mat-slide-toggle {
        .mat-slide-toggle-content {
            color: map-get(map-get($custom-dark-palette, contrast), 400);
        }
    }
}
