.mat-mdc-card-outlined {
    border: none;
    padding: 16px;
}

.mat-mdc-card-content {
    padding: 0 !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: 14px !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    align-items: flex-end !important;
}

.mdc-list-item__primary-text {
    font-size: 16px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-text-field__input:read-only {
    opacity: 0.5;
    pointer-events: none;
}

.readonly-datepicker .mat-mdc-form-field-flex {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

.mat-mdc-card {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.mat-drawer-container,
div.mat-mdc-select-panel .mat-mdc-option,
.mat-mdc-row,
.mat-mdc-select-value,
.mat-mdc-option.mdc-list-item,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input,
.mat-calendar-body-cell-content,
.mat-mdc-outlined-button:not(:disabled) {
    color: $brand-color-primary !important;
}

.mat-calendar-body-selected {
    color: #fff !important;
}

.mat-sort-header-container {
    font-size: 13px;
    color: $brand-color-primary !important;
    opacity: 0.64;
}

.mdc-button__label {
    font-size: 14px;
}

.light-theme .mat-mdc-unelevated-button.mat-warn {
    color: #fff;
}

.mat-mdc-form-field {
    --mat-form-field-container-text-size: 14px;
}

.mat-mdc-unelevated-button .mat-mdc-button-touch-target {
    display: none !important;
}

.button-edit {
    .mdc-button__label {
        color: #4b5b6bde;
        font-size: 21px !important;
    }
}

.mat-mdc-dialog-container .mdc-dialog__title {
    --mdc-dialog-subhead-size: 20px;
    --mdc-dialog-subhead-color: #4b5b6b;
    font-weight: 500 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    --mdc-dialog-supporting-text-size: 14px;
    --mdc-dialog-supporting-text-color: #4b5b6b;
    margin-top: 20px;
}

.mat-mdc-button:not(:disabled) {
    --mdc-text-button-label-text-color: #4b5b6b;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    --mdc-snackbar-supporting-text-size: 14px;
}

.mdc-snackbar__actions {
    width: 50px;
}

.button-create-new-task {
    border: solid 1px #4b5b6b !important;
}

.mat-mdc-form-field-error {
    font-size: 12px;
}

.mat-mdc-icon-button,
.mat-mdc-menu-item {
    color: $brand-color-primary !important;
}

.mat-mdc-menu-item .mat-icon-no-color {
    color: $brand-color-primary !important;
    opacity: 0.6;
}

.mat-mdc-cell {
    --mat-table-row-item-outline-color: solid 1px $brand-color-primary !important;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: solid 1px $brand-color-primary !important;
}

.mdc-floating-label {
    color: $brand-color-primary !important;
    padding-right: 25px;
    opacity: 0.7;
}

th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
    padding-left: 24px !important;
}

th.mat-mdc-header-cell:last-of-type,
td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
    padding-right: 24px !important;
}
