@import "./core/styles/index.scss";

html,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
}

.app-theme {
    .mat-drawer-container[fullscreen] {
        top: 65px;
    }

    .app-menu-items {
        margin-top: 0;
        margin-left: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 56px;
    }

    .mat-toolbar {
        padding-left: 7px;

        .app-icon {
            margin: 10px 0 0 0;
            max-height: 80%;
        }
    }
}

// position main navigation
.top-nav {
    width: 80%;
    margin: 0 auto;

    .mat-nav-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        mat-icon,
        #nav-link_close_toggle {
            display: none !important;
        }

        .mat-mdc-list-item {
            width: auto;

            .mat-list-text {
                padding-left: 0 !important;
            }
        }
    }
}

#site-nav_link_version {
    display: none !important;
}
